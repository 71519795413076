
import { computed, defineComponent, ref } from 'vue'

import IconSadSmile from '@web/components/icons/IconSadSmile.vue'
import AsModal from '@web/components/AsModal.vue'
import { EXTEND_MODEL, ExtendModelType } from '@web/consts/CourseExtentends'
import urlParse from 'url-parse'
import { getUrlWithAuthParams } from '@web/common/Utils'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    IconSadSmile,
    AsModal
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    button: {
      type: String,
      default: ''
    },
    gaAction: {
      type: String,
      default: 'prolongation'
    },
    showCritical: {
      type: Boolean,
      default: true
    },
    showWarning: {
      type: Boolean,
      default: false
    },
    isOptionalPurchase: {
      type: Boolean,
      default: true
    },
    extendModel: {
      type: String as () => ExtendModelType.STANDARD_EXTEND | ExtendModelType.NEW_BUY_EXTEND | ExtendModelType.DISABLED_EXTEND | ExtendModelType.SUPPORT_EXTEND,
      default: EXTEND_MODEL.STANDARD_EXTEND
    },
    extendPageUrl: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const router = useRouter()

    const isOpenModal = ref(false)

    const isShowButton = computed(() => props.extendModel !== EXTEND_MODEL.DISABLED_EXTEND)

    function openModal () {
      if (props.isOptionalPurchase) {
        router.push({ name: 'subscriptions', params: { warning: 'true' } })
      } else if (props.extendModel === EXTEND_MODEL.NEW_BUY_EXTEND && props.extendPageUrl) {
        const urlInstance = urlParse(props.extendPageUrl, true)
        urlInstance.set('query', {
          ...urlInstance.query,
          utm_source: 'lk',
          utm_medium: 'extend_button'
        })
        window.open(getUrlWithAuthParams(urlInstance.toString()), '_blank')?.focus()
      } else {
        isOpenModal.value = true
      }
    }

    function closeModal () {
      isOpenModal.value = false
    }

    return {
      isOpenModal,
      isShowButton,
      openModal,
      closeModal
    }
  }
})
