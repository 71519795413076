import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_renew_modal = _resolveComponent("as-renew-modal")!
  const _component_as_renew = _resolveComponent("as-renew")!

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_component_as_renew, {
        key: 0,
        "show-critical": _ctx.isShowCritical,
        "show-warning": _ctx.isShowWarning,
        button: _ctx.btnText,
        class: _normalizeClass({ '-error': _ctx.isCourseEnded }),
        "data-cy": `renew_${_ctx.stream.id}`,
        "is-optional-purchase": _ctx.stream.isOptionalPurchase,
        "extend-model": _ctx.course.extendModel,
        "extend-page-url": _ctx.course.extendPageUrl
      }, {
        default: _withCtx(({ close }) => [
          _createVNode(_component_as_renew_modal, {
            type: "course",
            streamId: _ctx.stream.id,
            onClose: close
          }, null, 8, ["streamId", "onClose"])
        ]),
        _: 1
      }, 8, ["show-critical", "show-warning", "button", "class", "data-cy", "is-optional-purchase", "extend-model", "extend-page-url"]))
    : _createCommentVNode("", true)
}