import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height", "viewBox", "aria-labelledby"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.width,
    height: _ctx.height,
    viewBox: _ctx.viewBox,
    "aria-labelledby": _ctx.iconName,
    role: "presentation"
  }, [
    _createElementVNode("title", { id: _ctx.iconName }, _toDisplayString(_ctx.iconName) + " icon", 9, _hoisted_2),
    _createElementVNode("g", { fill: _ctx.iconColor }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_3)
  ], 8, _hoisted_1))
}