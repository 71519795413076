import { ref, computed } from 'vue'
import Time from '@web/common/Time'
import { daysNoticeRenew } from '@web/common/ExternalConfig/getters'
import { Stream } from '@web/store/types/modules/stream'
import i18n from '@web/plugins/i18n'
import STREAM_STATUS from '@web/consts/StreamStatus'

function useRenewCourse (stream: Stream) {
  const DAYS_NOTICE_RENEW = daysNoticeRenew()
  const endDate = Number(stream.endDate)
  const criticalExtendButtonDays = typeof stream.criticalExtendButtonDays === 'number' ? stream.criticalExtendButtonDays : DAYS_NOTICE_RENEW
  const warningExtendButtonDays = stream.warningExtendButtonDays
  const isOpenModal = ref(false)
  const isShowCritical = computed(() => {
    if (criticalExtendButtonDays === 0) {
      return true
    }
    const time = new Date()
    time.setDate(time.getDate() + criticalExtendButtonDays)
    time.setHours(0)
    time.setMinutes(0)
    time.setSeconds(0)
    return time.getTime() > endDate
  })
  const isShowWarning = computed(() => {
    if (isShowCritical.value || typeof warningExtendButtonDays !== 'number') {
      return false
    }
    if (warningExtendButtonDays === 0) {
      return true
    }
    const time = new Date()
    time.setDate(time.getDate() + warningExtendButtonDays)
    time.setHours(0)
    time.setMinutes(0)
    time.setSeconds(0)
    return time.getTime() > endDate
  })
  const isShow = computed(() => {
    if (stream.status !== STREAM_STATUS.STOPPED && stream.status !== STREAM_STATUS.ENDED && stream.paymentSubscriptionId) {
      return false
    }
    return isShowCritical.value || isShowWarning.value
  })

  const courseEndBy = computed(() => {
    return `${i18n.global.t('courseEndBy')} ${Time(endDate).fromNow()}`
  })
  const isCourseEnded = computed(() => endDate < Date.now())

  const label = computed(() => isCourseEnded.value ? i18n.global.t('subscribeEnded') : courseEndBy.value)
  const button = computed(() => isCourseEnded.value ? i18n.global.t('goToPaymentFromUnblockCourse') : i18n.global.t('goToPaymentFromRenewCourse'))

  function openModal () {
    isOpenModal.value = true
  }

  return {
    isOpenModal,
    endDate,
    isShow,
    isShowCritical,
    isShowWarning,
    courseEndBy,
    isCourseEnded,
    label,
    button,
    openModal
  }
}

export default useRenewCourse
