import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, renderSlot as _renderSlot, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ec0acb90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "asRenew" }
const _hoisted_2 = {
  key: 0,
  class: "asRenew_warning"
}
const _hoisted_3 = {
  key: 0,
  class: "asRenew_text"
}
const _hoisted_4 = ["data-analytics-element-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_sad_smile = _resolveComponent("icon-sad-smile")!
  const _component_as_modal = _resolveComponent("as-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showWarning)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('ifNeedMoreTime')) + " " + _toDisplayString(_ctx.$t('youCan')) + " ", 1),
          _createElementVNode("a", {
            class: "asRenew_link",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
          }, _toDisplayString(_ctx.$t('courseRenew')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showCritical)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.label)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _createVNode(_component_icon_sad_smile),
                _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isShowButton)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                "data-analytics": "",
                "data-analytics-element-type": "other",
                "data-analytics-element-id": _ctx.gaAction,
                "data-cy": "renew_button",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args))),
                class: "asRenew_button"
              }, _toDisplayString(_ctx.button), 9, _hoisted_4))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    _createVNode(_component_as_modal, {
      modelValue: _ctx.isOpenModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isOpenModal) = $event))
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", { close: _ctx.closeModal }, undefined, true)
      ]),
      _: 3
    }, 8, ["modelValue"])
  ]))
}