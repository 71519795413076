
import { computed, defineComponent } from 'vue'

import AsRenew from '@web/components/AsRenew.vue'
import AsRenewModal from '@web/components/AsRenewModal.vue'

import useRenewCourse from '@web/composition/useRenewCourse'
import { Stream } from '@web/store/types/modules/stream'
import { useStore } from '@web/store'
import i18n from '@web/plugins/i18n'
import { EXTEND_MODEL } from '@web/consts/CourseExtentends'

export default defineComponent({
  props: {
    stream: {
      type: Object as () => Stream,
      required: true
    }
  },
  setup (props) {
    const store = useStore()

    const course = computed(() => store.getters['course/getCourseById'](props.stream.courseId))
    const btnText = computed(() => {
      if (course.value?.extendModel === EXTEND_MODEL.NEW_BUY_EXTEND) {
        return i18n.global.t('courseRenew')
      }

      return i18n.global.t('courseRenew')
    })

    return {
      ...useRenewCourse(props.stream),
      course,
      btnText
    }
  },
  components: {
    AsRenew,
    AsRenewModal
  }
})
